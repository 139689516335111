@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import 'variables.less';

html, body {
  height: 100%;
}

:root {
  --jc-primary-color: @primary-color;
  --jc-info-color: @info-color;
}

.ant-form-item-explain {
  &.ant-form-item-explain-error {
    display: none !important;
  }
}

// add this class where we needs to allow error
.ant-from-allow-error {
  .ant-form-item-explain-error {
    display: block !important;
  }
}

.success-notification {
  background-color: @primary-color;
  color: #fff;
  font-size: 1.1rem;

  .ant-notification-close-x,
  .ant-notification-notice-message { color: #fff; }
}

.error-notification {
  background-color: @danger-color;
  color: #fff;
  font-size: 1.1rem;

  .ant-notification-close-x,
  .ant-notification-notice-message { color: #fff; }
}
@hd-screen-down: ~'(max-width:1280px)';@wide-screen-down: ~'(max-width:1199px)';@desktop-down: ~'(max-width:991px)';@tablet-down: ~'(max-width:767px)';@phone-down: ~'(max-width:480px)';@small-phone-down: ~'(max-width:320px)';@primary-color: rgba(19, 167, 61, 100);@info-color: rgba(41, 74, 147, 100);@danger-color: #a71d13;@layout-body-background: #fff;@border-radius-base: 4px;@light-gray-color: #f9f9fb;@orange-color: #fbae17;@purple-color: rgba(57, 26, 131, 1);@steel-color: rgba(117, 137, 141, 1);@light-blue-color: rgba(51, 156, 215, 1);